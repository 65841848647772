import styled from "styled-components"

import { DocumentHead } from "src/router/DocumentHead"

/** @deprecated Don't use this directly; use MainView instead */
export function MainContentBox({
  metaTitle,
  banner = null,
  children,
  ...rest
}: {
  metaTitle?: string
  banner?: React.ReactNode
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  rest?: any
}) {
  return (
    <>
      <DocumentHead title={metaTitle} />
      {banner}
      <Box {...rest}>{children}</Box>
    </>
  )
}

const Box = styled.div``
